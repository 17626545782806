import {TwoDigitConverterPipe} from './twoDigitConverter/two-digit-converter.pipe';
import {NgModule} from '@angular/core';
import {MomentPipe} from './moment/moment.pipe';
import {SitePipe} from './site/site.pipe';
import {RoundPipe} from './round/round.pipe';
import {DriverPipe} from './Driver/driver.pipe';
import {ConvertStatusPipe} from './convertStatus/convert-status.pipe';
import {MarkdownPipe} from './markdown/markdown.pipe';
import {RoundInstructionPipe} from './round-instruction/round-instruction.pipe';
import {ContainerTypePipe} from './containerType/container-type.pipe';
import {SiteTypeIconPipe} from './siteType/site-type-icon.pipe';
import {SiteTypeColorPipe} from './siteType/site-type-color.pipe';
import {CarPipe} from './car/car.pipe';
import {UserPipe} from './user/user.pipe';
import {TranslateAuthoritiesPipe} from './authorities/translate-authorities.pipe';
import {DriverIdToFullNamePipe} from './driverIdToFullName/driver-id-to-full-name.pipe';
import {RoundStartingDelayPipe} from './roundStartingDelay/round-starting-delay.pipe';

@NgModule({
    declarations: [MomentPipe, SitePipe, RoundPipe, DriverPipe, ConvertStatusPipe, MarkdownPipe, RoundInstructionPipe,
        ContainerTypePipe, SiteTypeIconPipe, SiteTypeColorPipe, CarPipe, UserPipe, TranslateAuthoritiesPipe, DriverIdToFullNamePipe,
        RoundStartingDelayPipe, TwoDigitConverterPipe],
    imports: [],
    exports: [MomentPipe, SitePipe, RoundPipe, DriverPipe, ConvertStatusPipe, MarkdownPipe, RoundInstructionPipe,
        ContainerTypePipe, SiteTypeIconPipe, SiteTypeColorPipe, UserPipe, CarPipe, TranslateAuthoritiesPipe, RoundStartingDelayPipe,
        TwoDigitConverterPipe]
})
export class PipesModule {
}
