import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'site'
})
export class SitePipe implements PipeTransform {

    transform(siteId, allSites) {
        if (siteId !== undefined && allSites !== undefined) {
            const findSite = allSites.findIndex(x => x.id === siteId);
            if (findSite !== -1) {
                return allSites[findSite].name;
            } else {
                return 'site non trouvé';
            }
        } else {
            return 'pas de site enregistré';
        }
    }
}
