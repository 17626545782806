import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'containerType'
})
export class ContainerTypePipe implements PipeTransform {

    transform(containerTypeId, allContainerTypes) {
        if (containerTypeId !== undefined && allContainerTypes !== undefined) {
            const findContainerType = allContainerTypes.findIndex(x => x.id === containerTypeId);
            if (findContainerType !== -1) {
                return allContainerTypes[findContainerType].name;
            } else {
                return 'type non trouvé';
            }
        }
    }

}
