import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {StatusConnexionEnum} from '../../../entity/enum/status-connexion.enum';

@Injectable({
    providedIn: 'root'
})
export class RootService {

    public URL: string;
    public isConnected$ = new BehaviorSubject<StatusConnexionEnum>(StatusConnexionEnum.notYet);

    constructor(public http: HttpClient) {
        this.URL = environment.back_url;
    }
}
