import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../entity/classes/user/user';

@Pipe({
    name: 'user'
})
export class UserPipe implements PipeTransform {

    transform(userId: number, users: User[]): string {
        if (userId !== undefined && users !== undefined) {
            const findedUserIndex = users.findIndex(x => x.id === userId);
            if (findedUserIndex !== -1) {
                return users[findedUserIndex].firstName + ' ' + users[findedUserIndex].lastName;
            } else {
                return 'user non trouvé';
            }
        } else {
            return 'pas de user enregistré';
        }
    }

}
