import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateAuthorities'
})
export class TranslateAuthoritiesPipe implements PipeTransform {

  transform(auth: string): string {
    switch (auth) {
      case 'ROLE_DRIVER' : return 'Coursier';
      case 'ROLE_DRIVER_MANAGER' : return 'Manager coursier';
      case 'ROLE_GLOBAL_MANAGER' : return 'Manager global';
      case 'ROLE_LABORATORY_MANAGER' : return 'Manager laboratoire';
      case 'ROLE_MANAGER' : return 'Manager';
      case 'ROLE_PICK_UP' : return 'Infirrmiere';
      case 'ROLE_VIEWER' : return 'Client';
    }
  }

}
