import {Pipe, PipeTransform} from '@angular/core';
import {SiteTypeDto} from '../../gateways/dtos/site-type-dto';

@Pipe({
    name: 'siteTypeColor'
})
export class SiteTypeColorPipe implements PipeTransform {

    transform(siteTypeId: number, allSiteTypes: SiteTypeDto[]): string {
        if (siteTypeId !== undefined && allSiteTypes !== undefined) {
            const findSiteType = allSiteTypes.findIndex(x => x.id === siteTypeId);
            if (findSiteType !== -1) {
                return allSiteTypes[findSiteType].color;
            } else {
                return 'black';
            }
        }
    }

}
