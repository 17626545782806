import {Component, OnInit} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {RoleService} from './gateways/services/roleService/role.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public appPages = [
        {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'home'
        },
        {
            title: 'Paramètres',
            url: '/parameter',
            icon: 'settings'
        },
        {
            title: 'Tournées',
            url: '/round-of-day',
            icon: 'trail-sign'
        },
        {
            title: 'Recherche',
            url: '/search',
            icon: 'search'
        },
        {
            title: 'Cargaison manager',
            url: '/cargaison-manager',
            icon: 'bus'
        },
        {
            title: 'Container manager',
            url: '/container-manager',
            icon: 'briefcase'
        }
    ];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        public roleService: RoleService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }
    }

    navigateWithoutParams(page) {
        this.router.navigate([page], {replaceUrl: true});
    }

    // EXEMPLE POUR APRES
    // navigateWithParams(page, instruction1, instruction2) {
    //     this.router.navigate([page], {
    //         replaceUrl: true,
    //         queryParams: {
    //             roundInstructionId: localStorage.getItem(instruction1),
    //             carId: localStorage.getItem(instruction2)
    //         }
    //     });
    // }
}
