import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'roundInstruction'
})
export class RoundInstructionPipe implements PipeTransform {

    transform(roundInstructionId, allRoundInstructions) {
        if (roundInstructionId !== undefined && allRoundInstructions !== undefined) {
            const findRoundInstruction = allRoundInstructions.findIndex(x => x.id === roundInstructionId);
            if (findRoundInstruction !== -1) {
                return allRoundInstructions[findRoundInstruction].name;
            } else {
                return 'tournée non trouvée';
            }
        }
    }

}
