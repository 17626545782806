import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'twoDigitConverter'
})
export class TwoDigitConverterPipe implements PipeTransform {

    transform(num: string): string {
        return ('0' + num).slice(-2);
    }

}
