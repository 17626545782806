import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driver'
})
export class DriverPipe implements PipeTransform {

  transform(roundId, allUser, allRounds){
    if (roundId){
      const index = allRounds.findIndex(x => x.id == roundId);
      if ( index != -1){
        const userId = allRounds[index].driverId;
        const index2 = allUser.findIndex( x => x.id == userId);
        if ( index2 != -1){
          return allUser[index2].firstName + ' ' + allUser[index2].lastName;
        }else{
          console.log(' error');
        }
      }else{
        console.log(' error');
      }
    }
  }

}
