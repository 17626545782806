import {Pipe, PipeTransform} from '@angular/core';
import {RoundControlStatusDto} from '../../gateways/dtos/round-control-status-dto';
import * as moment from 'moment';

@Pipe({
    name: 'roundStartingDelay'
})
export class RoundStartingDelayPipe implements PipeTransform {

    transform(roundControlStatus: RoundControlStatusDto): string {
        // const date = moment('2022-04-06').format('YYYY-MM-DD');
        const date = moment().format('YYYY-MM-DD');
        const time = roundControlStatus.checkPoint.hourTime + ':' + roundControlStatus.checkPoint.minuteTime;
        const checkPointTime = moment(date + ' ' + time);
        let rcpTime;
        if (roundControlStatus.roundCheckPoint != null) {
            rcpTime = moment(roundControlStatus.roundCheckPoint.arrivedAt);
        } else {
            rcpTime = moment();
        }
        return checkPointTime.diff(rcpTime, 'minutes').toString();
    }

}
