import { Pipe, PipeTransform } from '@angular/core';
import {UserDto} from '../../gateways/dtos/user-dto';

@Pipe({
  name: 'driverIdToFullName'
})
export class DriverIdToFullNamePipe implements PipeTransform {

  transform(id: number, allUsers: UserDto[]): string {

    const findUser = allUsers.findIndex(x => x.id === id);
    if (findUser !== -1){return allUsers[findUser].firstName + ' ' + allUsers[findUser].lastName}
    else{ return null; }

  }

}
