import {Pipe, PipeTransform} from '@angular/core';
import {Car} from '../../entity/classes/car/car';

@Pipe({
    name: 'car'
})
export class CarPipe implements PipeTransform {

    transform(carId: number, cars: Car[]): string {
        if (carId !== undefined && cars !== undefined) {
            const findedeCarIndex = cars.findIndex(x => x.id === carId);
            if (findedeCarIndex !== -1) {
                return cars[findedeCarIndex].plateNumber;
            } else {
                return 'car non trouvé';
            }
        } else {
            return 'pas de car enregistré';
        }
    }

}
