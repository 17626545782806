import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'round'
})
export class RoundPipe implements PipeTransform {

    transform(roundId, allRound) {
        if (allRound) {
            const findName = allRound.findIndex(x => x.id === roundId);
            return allRound[findName].name;
        } else {
            return 'Tournée non trouvée';
        }
    }

}
