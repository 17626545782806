export enum RoleEnum {
    global_manager = 'ROLE_GLOBAL_MANAGER',
    manager = 'ROLE_MANAGER',
    user = 'ROLE_USER',
    driver = 'ROLE_DRIVER',
    viewer = 'ROLE_VIEWER',
    pickUp = 'ROLE_PICK_UP',
    driver_manager = 'ROLE_DRIVER_MANAGER',
    labo_manager = 'ROLE_LABORATORY_MANAGER'
}
